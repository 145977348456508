@import './themes/themes.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--text-primary);
  background-color: var(--background);
  transition: background-color var(--transition-speed) ease-in-out, 
              color var(--transition-speed) ease-in-out;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-tight);
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
}

h1 {
  font-size: var(--font-size-xl);
}

h2 {
  font-size: var(--font-size-lg);
}

h3 {
  font-size: var(--font-size-md);
}

h4 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
}

p {
  margin-bottom: var(--spacing-md);
}

a {
  color: var(--link);
  text-decoration: none;
  transition: color var(--transition-speed) ease-in-out;
}

a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  background: none;
  border: none;
}

input, textarea, select {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  padding: var(--spacing-md);
  background-color: var(--surface);
  color: var(--text-primary);
  border: var(--border-width) solid var(--border);
  border-radius: var(--border-radius);
  transition: border-color var(--transition-speed) ease-in-out;
  
  &:focus {
    outline: none;
    border-color: var(--primary);
  }
  
  &::placeholder {
    color: var(--text-secondary);
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

.card {
  background-color: var(--surface);
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  border: var(--border-width) solid var(--border);
  transition: all var(--transition-speed) ease-in-out;
}

.btn {
  display: inline-block;
  padding: var(--spacing-md) var(--spacing-xl);
  border-radius: var(--border-radius);
  transition: all var(--transition-speed) ease-in-out;
  text-align: center;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  
  &.btn-primary {
    background-color: var(--primary);
    color: var(--button-text);
    
    &:hover {
      opacity: 0.9;
    }
  }
  
  &.btn-secondary {
    background-color: var(--secondary);
    color: var(--text-primary);
    
    &:hover {
      background-color: var(--gray-300);
    }
  }
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-success {
  color: var(--success);
}

.text-error {
  color: var(--error);
}

.text-warning {
  color: var(--warning);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
} 