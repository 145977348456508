.app {
  text-align: center;
  min-height: 100vh;
  background-color: var(--background);
  color: var(--text-primary);
  transition: all var(--transition-speed) ease-in-out;
  padding-top: 80px; /* Match the header height */
}

.app-main {
  // Remove padding-top since we're handling it in the Hero component
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl) 0;
}

h1 {
  margin-bottom: var(--spacing-md);
  color: var(--primary);
}

.subtitle {
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-lg);
  color: var(--text-secondary);
}

.theme-info {
  background-color: var(--surface);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-lg);
  border: var(--border-width) solid var(--border);
}

.theme-name {
  font-weight: var(--font-weight-bold);
  color: var(--primary);
  text-transform: capitalize;
}

.app-link {
  color: var(--link);
  font-weight: var(--font-weight-semibold);
  transition: color var(--transition-speed) ease-in-out;
  
  &:hover {
    color: var(--primary);
  }
}

.demo-section {
  margin-top: var(--spacing-2xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg);
  width: 100%;
  max-width: 800px;
}

.color-palette {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: var(--spacing-md);
  width: 100%;
}

.color-swatch {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
  
  .swatch {
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-sm);
    border: var(--border-width) solid var(--border);
  }
  
  .swatch-name {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
  }
}

/* Animation for logos or other elements if needed */
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
  width: 100%;
}

.iphone-frame {
  width: 280px;
  height: 580px;
  background-color: #f1f1f1;
  border-radius: 36px;
  border: 12px solid #333;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }
}

.app-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}

/* Form styles for dialog */
input, select, button {
  font-family: inherit;
}

/* Button styles */
.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-speed) ease;
  border: none;
  font-size: var(--font-size-md);
  
  &.btn-primary {
    background-color: var(--primary);
    color: var(--primary-contrast);
    
    &:hover {
      background-color: var(--primary-dark);
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    &:active {
      transform: translateY(0);
    }
  }
}
