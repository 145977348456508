.cta-container {
  width: 100%;
  padding: var(--spacing-xl) 0;
  background: var(--background);
}

.cta-section {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: var(--spacing-xl) var(--spacing-xl);
  text-align: center;
  border-radius: 0;
  box-shadow: none;
  
  @media (max-width: 968px) {
    padding: var(--spacing-xl) var(--spacing-lg);
  }
  
  &__headline {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: var(--spacing-md);
    color: var(--text-primary);
    
    @media (max-width: 768px) {
      font-size: 2.25rem;
    }
  }
  
  &__description {
    font-size: 1.25rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto var(--spacing-xl);
    color: var(--text-primary);
    opacity: 0.9;
    
    @media (max-width: 768px) {
      font-size: 1.125rem;
    }
  }
  
  &__button-container {
    margin-bottom: var(--spacing-md);
    
    .btn {
      padding: 12px 32px;
      font-size: 1.125rem;
    }
  }
} 