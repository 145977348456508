.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--background);
  border-bottom: 1px solid var(--border);
  padding: 0 var(--spacing-md);
  height: 80px;
  display: flex;
  align-items: center;

  .header-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 var(--spacing-lg);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    h1 {
      margin: 0;
      font-size: 1.5rem;
      color: var(--text-primary);
    }

    a {
      text-decoration: none;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
    position: relative;
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--text-primary);
    cursor: pointer;
    padding: var(--spacing-sm);
    margin-left: auto;
    z-index: 1010; /* Above the overlay */

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1005;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .main-nav {
    .nav-links {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: var(--spacing-lg);

      li {
        position: relative;
        
        &.active {
          a {
            color: var(--primary);
            
            &:after {
              transform: scaleX(1);
            }
          }
        }

        a {
          color: var(--text-primary);
          text-decoration: none;
          font-weight: 500;
          font-size: 1rem;
          padding: var(--spacing-sm) 0;
          position: relative;
          transition: color var(--transition-speed) ease;

          &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--primary);
            transform: scaleX(0);
            transform-origin: center;
            transition: transform var(--transition-speed) ease;
          }

          &:hover {
            color: var(--primary);

            &:after {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }

  .mobile-only {
    display: none;
  }

  .theme-toggle-button {
    background: none;
    border: none;
    color: var(--text-primary);
    cursor: pointer;
    padding: var(--spacing-sm);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color var(--transition-speed) ease;

    &:hover {
      background-color: var(--surface);
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .theme-toggle-container {
    display: flex;
    justify-content: center;
  }

  .theme-toggle-item {
    .theme-toggle-button {
      width: 100%;
      border-radius: 4px;
      justify-content: center;
      padding: var(--spacing-md);
    }
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    .menu-toggle {
      display: flex;
    }

    .mobile-only {
      display: block;
    }

    .desktop-only {
      display: none;
    }

    .menu-overlay {
      display: block;
    }

    .main-nav {
      position: fixed;
      top: 0;
      right: -100%;
      width: 75%;
      max-width: 300px;
      height: 100vh;
      background-color: var(--background);
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
      padding: 100px var(--spacing-lg) var(--spacing-lg);
      transition: right 0.3s ease;
      z-index: 1008;

      &.open {
        right: 0;
      }

      .nav-links {
        flex-direction: column;
        gap: var(--spacing-xl);

        li {
          width: 100%;
          display: flex;
          justify-content: center;

          a {
            display: block;
            width: 100%;
            padding: var(--spacing-md) 0;
            font-size: 1.1rem;
            text-align: center;

            &:after {
              bottom: 0;
            }
          }
        }
      }
    }
  }
} 