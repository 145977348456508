@import '../variables.scss';

:root {
  // Default light theme (base colors)
  --primary: #{$primary-light};
  --primary-dark: #{$primary-dark-light};
  --secondary: #{$secondary-light};
  --accent: #{$accent-light};
  --text-primary: #{$text-primary-light};
  --text-secondary: #{$text-secondary-light};
  --background: #{$background-light};
  --surface: #{$surface-light};
  
  // Additional theme colors
  --border: #{$border-light};
  --success: #{$success-light};
  --error: #{$error-light};
  --warning: #{$warning-light};
  --link: #{$link-light};
  --button-text: #{$button-text-light};
  --input-bg: #{$input-bg-light};
  --hover: #{$hover-light};
  --primary-rgb: #{$primary-rgb-light};
  --primary-contrast: #{$button-text-light};
  
  // Raw color palette for utility classes
  --white: #{$white};
  --black: #{$black};
  
  // Gray scale
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --gray-900: #{$gray-900};
  
  // Blues
  --blue-100: #{$blue-100};
  --blue-200: #{$blue-200};
  --blue-300: #{$blue-300};
  --blue-400: #{$blue-400};
  --blue-500: #{$blue-500};
  --blue-600: #{$blue-600};
  --blue-700: #{$blue-700};
  --blue-800: #{$blue-800};
  --blue-900: #{$blue-900};
  
  // Reds
  --red-100: #{$red-100};
  --red-200: #{$red-200};
  --red-300: #{$red-300};
  --red-400: #{$red-400};
  --red-500: #{$red-500};
  --red-600: #{$red-600};
  --red-700: #{$red-700};
  --red-800: #{$red-800};
  --red-900: #{$red-900};
  
  // Greens
  --green-100: #{$green-100};
  --green-200: #{$green-200};
  --green-300: #{$green-300};
  --green-400: #{$green-400};
  --green-500: #{$green-500};
  --green-600: #{$green-600};
  --green-700: #{$green-700};
  --green-800: #{$green-800};
  --green-900: #{$green-900};
  
  // Yellows
  --yellow-100: #{$yellow-100};
  --yellow-200: #{$yellow-200};
  --yellow-300: #{$yellow-300};
  --yellow-400: #{$yellow-400};
  --yellow-500: #{$yellow-500};
  --yellow-600: #{$yellow-600};
  --yellow-700: #{$yellow-700};
  --yellow-800: #{$yellow-800};
  --yellow-900: #{$yellow-900};
  
  // Typography
  --font-family-primary: #{$font-family-primary};
  --font-family-secondary: #{$font-family-secondary};
  --font-family-mono: #{$font-family-mono};
  
  // Font sizes
  --font-size-xs: #{$font-size-xs};
  --font-size-sm: #{$font-size-sm};
  --font-size-base: #{$font-size-base};
  --font-size-md: #{$font-size-md};
  --font-size-lg: #{$font-size-lg};
  --font-size-xl: #{$font-size-xl};
  
  // Font weights
  --font-weight-light: #{$font-weight-light};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-semibold: #{$font-weight-semibold};
  --font-weight-bold: #{$font-weight-bold};
  
  // Line heights
  --line-height-tight: #{$line-height-tight};
  --line-height-base: #{$line-height-base};
  --line-height-loose: #{$line-height-loose};
  
  // Spacing
  --spacing-xs: #{$spacing-xs};
  --spacing-sm: #{$spacing-sm};
  --spacing-md: #{$spacing-md};
  --spacing-lg: #{$spacing-lg};
  --spacing-xl: #{$spacing-xl};
  --spacing-2xl: #{$spacing-2xl};
  
  // Layout
  --border-radius: #{$border-radius};
  --border-radius-lg: #{$border-radius-lg};
  --border-width: #{$border-width};
  
  // Transitions
  --transition-speed: #{$transition-speed};
  
  // Shadows
  --box-shadow: #{$box-shadow};
}

// Dark theme css variables
[data-theme='dark'] {
  // Base theme colors
  --primary: #{$primary-dark};
  --primary-dark: #{$primary-dark-dark};
  --secondary: #{$secondary-dark};
  --accent: #{$accent-dark};
  --text-primary: #{$text-primary-dark};
  --text-secondary: #{$text-secondary-dark};
  --background: #{$background-dark};
  --surface: #{$surface-dark};
  
  // Additional theme colors
  --border: #{$border-dark};
  --success: #{$success-dark};
  --error: #{$error-dark};
  --warning: #{$warning-dark};
  --link: #{$link-dark};
  --button-text: #{$button-text-dark};
  --input-bg: #{$input-bg-dark};
  --hover: #{$hover-dark};
  --primary-rgb: #{$primary-rgb-dark};
  --primary-contrast: #{$button-text-dark};
} 