.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.signup-dialog {
  background-color: var(--surface);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 500px;
  position: relative;
  overflow: hidden;
  border: var(--border-width) solid var(--border);
  text-align: left;

  h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-xs);
    color: var(--primary);
    font-size: var(--font-size-xl);
  }

  p {
    margin-bottom: var(--spacing-lg);
    color: var(--text-secondary);
    font-size: var(--font-size-md);
  }

  form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  &.success-dialog {
    text-align: center;
    
    .success-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-lg) 0;
      
      h2 {
        color: var(--success);
        margin-bottom: var(--spacing-md);
      }
      
      p {
        margin-bottom: var(--spacing-xl);
      }
      
      button {
        min-width: 120px;
      }
    }
  }
}

.close-button {
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  background: none;
  border: none;
  font-size: var(--font-size-xl);
  cursor: pointer;
  color: var(--text-secondary);
  line-height: 1;
  padding: var(--spacing-xs);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--transition-speed) ease;

  &:hover {
    background-color: var(--hover);
    color: var(--primary);
  }
}

.form-row {
  display: flex;
  gap: var(--spacing-md);
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }

  .form-group {
    flex: 1;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-sm);

  label {
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-xs);
    color: var(--text-primary);
    font-weight: var(--font-weight-medium);
  }

  input, select {
    padding: var(--spacing-sm);
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--border);
    background-color: var(--input-bg);
    color: var(--text-primary);
    font-size: var(--font-size-md);
    transition: border-color var(--transition-speed) ease;

    &:focus {
      outline: none;
      border-color: var(--primary);
      box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
    }
    
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding-right: 35px;
  }
}

.checkbox-group {
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-sm);

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0;
    accent-color: var(--primary);
  }

  label {
    margin-bottom: 0;
    font-size: var(--font-size-sm);
  }
}

.submit-button {
  margin-top: var(--spacing-md);
  width: 100%;
  padding: var(--spacing-md);
  font-weight: var(--font-weight-semibold);
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &.loading {
    position: relative;
    color: transparent;
    
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-top-color: white;
      animation: spin 0.8s infinite linear;
    }
  }
}

.error-message {
  background-color: rgba(var(--error), 0.1);
  border-left: 4px solid var(--error);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  color: var(--error);
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 