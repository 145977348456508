.roadmap-page {
  padding-top: 80px; // To account for fixed header
  
  .roadmap-hero {
    background-color: var(--surface);
    padding: var(--spacing-xl) 0;
    
    &__title {
      font-size: 2.5rem;
      color: var(--text-primary);
      margin-bottom: var(--spacing-lg);
      text-align: center;
    }
  }
  
  .roadmap-content {
    max-width: 1000px;
    margin: 0 auto;
    
    h2 {
      font-size: 1.8rem;
      color: var(--text-primary);
      margin-bottom: var(--spacing-md);
    }
    
    p {
      color: var(--text-secondary);
      line-height: 1.6;
      margin-bottom: var(--spacing-lg);
    }
  }
  
  .chrono-timeline-container {
    margin: var(--spacing-xl) 0;
    min-height: 800px;
    
    // Custom styling for react-chrono
    .timeline-main-wrapper {
      border-radius: var(--border-radius);
      overflow: hidden;
      
      // Remove top padding where controls used to be
      padding-top: 0 !important;
    }
    
    .timeline-card-content {
      padding: var(--spacing-md);
      background-color: var(--surface) !important;
      
      h3 {
        font-weight: 600;
      }
    }
    
    .timeline-item-title {
      font-weight: 600;
      background-color: transparent !important;
    }
    
    // Custom class styles
    .timeline-card {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease, box-shadow 0.2s ease;
      
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }
    }
    
    .timeline-card-title {
      font-weight: 600;
      margin-bottom: var(--spacing-sm);
    }
    
    .timeline-card-subtitle {
      margin-bottom: var(--spacing-md);
    }
    
    .timeline-card-text {
      line-height: 1.5;
    }
    
    // Override react-chrono default styles
    :global {
      .card-subtitle {
        color: var(--text-secondary) !important;
      }
      
      .card-description {
        color: var(--text-secondary) !important;
      }
      
      // Fix for when controls are hidden
      .timeline-main-wrapper {
        margin-top: 0 !important;
      }
      
      // Improve timeline circles
      .timeline-circle {
        transition: transform 0.2s ease;
        
        &:hover {
          transform: scale(1.2);
        }
      }
      
      // Improve timeline vertical line
      .vertical-item-row::before {
        background-color: var(--primary) !important;
        opacity: 0.6;
      }
    }
  }
  
  .roadmap-note {
    margin-top: var(--spacing-xl);
    padding: var(--spacing-md);
    background-color: var(--surface);
    border-radius: var(--border-radius);
    border-left: 4px solid var(--accent);
    
    p {
      margin-bottom: 0;
      font-style: italic;
      font-size: 0.9rem;
    }
  }
  
  // Media queries for responsiveness
  @media (max-width: 768px) {
    .chrono-timeline-container {
      min-height: 600px;
    }
  }
}