/* About Page Styles */
.about-page {
    padding-bottom: 4rem;
    
    .about-hero {
      padding: 3rem 0;
      margin-bottom: 2rem;
      
      &__title {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        color: var(--text-primary);
        text-align: center;
      }
      
      &__image {
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        
        .image-placeholder {
          background-color: var(--border);
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--text-secondary);
          font-size: 1.5rem;
          border-radius: 8px;
        }
      }
    }
    
    .about-content {
      max-width: 800px;
      margin: 0 auto;
      
      h2 {
        color: var(--text-primary);
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.75rem;
        position: relative;
        padding-bottom: 0.75rem;
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 60px;
          height: 3px;
          background-color: var(--primary);
        }
      }
      
      p {
        color: var(--text-secondary);
        line-height: 1.6;
        margin-bottom: 1.5rem;
        font-size: 1.1rem;
      }
    }
  } 