.scroll-section {
  padding: var(--spacing-xl) 0;
  background: var(--background);
  overflow: hidden;
}

.scroll-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xl);
  padding: 120px var(--spacing-xl);
  max-width: 1400px;
  margin: 0 auto;
  min-height: 80vh;
  
  &.image-left {
    flex-direction: row;
  }
  
  &.image-right {
    flex-direction: row-reverse;
  }
  
  @media (max-width: 968px) {
    &.image-left,
    &.image-right {
      flex-direction: column;
      padding: var(--spacing-xl) var(--spacing-lg);
      min-height: auto;
    }
  }

  &__content {
    flex: 1;
    max-width: 500px;
    padding: 0;

    @media (max-width: 968px) {
      max-width: 100%;
      padding: 0;
      margin-bottom: var(--spacing-xl);
      text-align: center;
    }

    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: var(--spacing-lg);
      color: var(--text-primary);
      
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.25rem;
      line-height: 1.6;
      color: var(--text-secondary);
    }
  }

  &__image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;

    @media (max-width: 968px) {
      max-width: 100%;
      margin: var(--spacing-lg) 0;
    }

    .iphone-frame {
      max-width: 100%;
      
      img {
        width: 100%;
        height: 100%;
        max-height: none;
        filter: none;
        border-radius: 24px;
        margin: 0;
      }
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 90vh;
      height: auto;
      object-fit: contain;
      display: block;
      margin: 0 auto;
      border-radius: 12px;
      filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.1));
    }
  }
} 