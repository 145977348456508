.hero {
  background: var(--background);
  overflow: hidden;
  min-height: calc(100vh - 80px);
  margin-top: 30px;
  text-align: left;
  
  @media (max-width: 768px) {
    margin-top: 10px;
    min-height: calc(100vh - 60px);
  }
  
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(var(--spacing-xl) * 0.6) 0 var(--spacing-xl);
    
    @media (max-width: 768px) {
      flex-direction: column;
      text-align: left;
      padding-top: var(--spacing-sm);
      padding-bottom: var(--spacing-lg);
    }
  }

  &__content {
    flex: 1;
    max-width: 600px;
    padding-right: var(--spacing-xl);

    @media (max-width: 768px) {
      padding-right: 0;
      margin-bottom: var(--spacing-lg);
    }
  }

  &__title {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: var(--spacing-lg);
    color: var(--text-primary);
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
      margin-bottom: var(--spacing-md);
    }
  }

  &__subtitle {
    font-size: 1.25rem;
    line-height: 1.6;
    color: var(--text-secondary);
    margin-bottom: var(--spacing-xl);
    
    @media (max-width: 768px) {
      margin-bottom: var(--spacing-lg);
    }
  }

  &__cta {
    display: flex;
    gap: var(--spacing-md);

    @media (max-width: 768px) {
      justify-content: center;
    }

    .btn {
      padding: 1rem 2rem;
      font-size: 1.125rem;
    }
  }

  &__image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    
    .iphone-frame {
      max-width: 100%;
      
      .hero__mockup {
        width: 100%;
        height: 100%;
        max-height: none;
        filter: none;
      }
    }
  }

  &__mockup {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: contain;
    filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.1));
  }
} 