/* How It Works Page Styles */
.how-it-works-page {
  padding-bottom: 4rem;
  text-align: left;
  
  .how-it-works-hero {
    padding: 3rem 0;
    margin-bottom: 2rem;
    
    &__title {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      color: var(--text-primary);
      text-align: center;
    }
  }
  
  .how-it-works-content {
    max-width: 800px;
    margin: 0 auto;
    
    .section {
      margin-bottom: 3rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    h2 {
      color: var(--text-primary);
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 1.75rem;
      position: relative;
      padding-bottom: 0.75rem;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 3px;
        background-color: var(--primary);
      }
    }
    
    p {
      color: var(--text-secondary);
      line-height: 1.6;
      margin-bottom: 1.5rem;
      font-size: 1.1rem;
    }
    
    ul {
      margin-bottom: 1.5rem;
      padding-left: 1.5rem;
      
      li {
        color: var(--text-secondary);
        margin-bottom: 0.75rem;
        line-height: 1.5;
        font-size: 1.1rem;
        position: relative;
        
        &::marker {
          color: var(--primary);
        }
      }
    }
  }
} 